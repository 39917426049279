<template>
  <div class="equipment-view-page container" v-if="equipment">
    <h1>Edit equipment</h1>
    <hr />

    <form>
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="name" :disabled="!currentAccount.hasPermission('equipments.edit')" />
      </div>

      <button type="button" class="btn btn-primary" :disabled="!currentAccount.hasPermission('equipments.edit')" @click="save">
        Save
      </button>
      <button type="button" class="btn btn-danger ms-2" :disabled="!currentAccount.hasPermission('equipments.remove')" @click="remove">
        Delete
      </button>

      <span class="ms-3 text-muted">
        <template v-if="equipment.updatedAt">
          Updated by <b>{{ equipment.updatedByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(equipment.updatedAt)) }}</b>
        </template>
        <template v-else>
          Created by <b class="font-weight-bold">{{ equipment.createdByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(equipment.createdAt)) }}</b>
        </template>
      </span>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTimeFormatter } from '@/utils';
import { findEquipment, updateEquipment, deleteEquipment } from '@/api';

export default {
  name: 'EquipmentEditPage',
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    name: '',

    equipment: null,

    dateTimeFormatter,
  }),
  methods: {
    async update(withReset = true) {
      try {
        if (withReset) {
          this.equipment = null;
        }

        const id = this.$route.params.id;
        if (!id) {
          return;
        }

        const response = await findEquipment(id);
        if (response?.errors) {
          throw new Error(response.errors[0].message);
        }

        this.equipment = response?.data?.equipment;
        if (!this.equipment) {
          throw new Error('Equipment not found');
        }

        this.name = this.equipment.name;
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      }
    },

    async save() {
      try {
        const data = {};

        if (this.name && this.name !== this.equipment.name) {
          data.name = this.name;
        }

        const response = await updateEquipment(this.$route.params.id, data);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Equipment was updated.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'equipments' }), 2000);
        this.update(false);
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    async remove() {
      try {
        const response = await deleteEquipment(this.$route.params.id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$router.push({ name: 'equipments' });
        this.$toast.success('Equipment was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
  activated() {
    this.update();
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.update();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.equipment-view-page {
  padding: 2em 0;
}

.control input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.control button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
